import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'desc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			/* 'filters[0][field]': 'delivery_person',
			'filters[0][operator]': '=',
			'filters[0][value]': '', */
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAllByDate(date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'date',
			'filters[0][operator]': '=',
			'filters[0][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAllTypeDiet() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 1000,
		}
		const { data } = await useJwt.get(API_ROUTES.type_diet.get, params)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.orderTimeline.save}/${id}`, data)
	},
	async reportOrder(dateInit, dateFinal, customer_id) {
		const params = {
			fecha_inicial: dateInit,
			fecha_final: dateFinal,
			customer_id: customer_id,
		}
		const { data } = await useJwt.get(API_ROUTES.report.get, params)

		return data
	},
}
